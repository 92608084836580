import styled from "styled-components";

const Heading = styled.h1`
  font-size: var(--32px);
  font-family: var(--kotori);
  color: var(--poke);
  align-self: center;
  margin-bottom: 72px;
  text-align: center;
`;

const Subheader = styled.p`
  font-size: 1.375rem;
  font-family: var(--kotori);
  margin-bottom: 16px;
  font-weight: 600;
`;

const Image = styled.img`
  border-radius: 18px;
  box-shadow: var(--light-shadow);
`;

const Paragraph = styled.p`
  color: var(--mono-900);
  font-weight: 300;
  margin-bottom: 48px;
  line-height: 1.8;
  font-size: var(--18px);
`;

const LI = styled.li`
  list-style-type: "⭐️";
  font-weight: 300;
  margin-left: 24px;
  padding-left: 12px;
  font-size: var(--18px);
`;

const UL = styled.ul`
  margin-top: -24px;
`;

export const components = {
  h1: Heading,
  h2: Subheader,
  img: Image,
  p: Paragraph,
  li: LI,
  ul: UL,
};
