import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    font-family: 'lexend', sans-serif;
    color: var(mono-900);
    outline-color: var(--poke);
  }

  html, body {
    height: 100%;
    padding: 0;
    margin: 0;
    background: var(--background-cider);
    scroll-behavior: smooth;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:focus {
      outline-offset: 2px;
    }
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  li {
    list-style: none;
  }

  #root, #__next {
    isolation: isolate;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @font-face {
    font-family: "Kotori Rose";
    src: url("/fonts/Kotori/KotoriRose-Bold.otf") format("opentype");
    font-weight: 700;
  }
      
  @font-face {
    font-family: "Kotori Rose";
    src: url("/fonts/Kotori/KotoriRose-Regular.otf") format("opentype");
    font-weight: 400;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-100.ttf") format("opentype");
    font-weight: 100;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-200.ttf") format("opentype");
    font-weight: 200;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-300.ttf") format("opentype");
    font-weight: 300;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-400.ttf") format("opentype");
    font-weight: 400;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-500.ttf") format("opentype");
    font-weight: 500;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-600.ttf") format("opentype");
    font-weight: 600;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-700.ttf") format("opentype");
    font-weight: 700;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-800.ttf") format("opentype");
    font-weight: 800;
  }

  @font-face {
    font-family: "Lexend";
    src: url("/fonts/Lexend/Lexend-900.ttf") format("opentype");
    font-weight: 900;
  }

  
  @font-face {
    font-family: "Roboto Mono";
    src: url("/fonts/Roboto-Mono/RobotoMono-300.ttf") format("opentype");
    font-weight: 300;
  }
  
  @font-face {
    font-family: "Roboto Mono";
    src: url("/fonts/Roboto-Mono/RobotoMono-400.ttf") format("opentype");
    font-weight: 400;
  }

  @font-face {
    font-family: "Roboto Mono";
    src: url("/fonts/Roboto-Mono/RobotoMono-500.ttf") format("opentype");
    font-weight: 500;
  }
`;
