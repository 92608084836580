import type { AppProps } from "next/app";
import * as Toast from "@radix-ui/react-toast";
import { GlobalStyles } from "@/styles/GlobalStyles";
import ContextProvider from "@/state/Context";
import { MDXProvider } from "@mdx-js/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { components } from "@/styles/MDXStyles";
import "@/styles/variables.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Toast.Provider duration={2500}>
          <ContextProvider>
            <MDXProvider components={components}>
              <GlobalStyles />
              <Component {...pageProps} />
            </MDXProvider>
          </ContextProvider>
        </Toast.Provider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
