import { createContext, useState, ReactNode, useContext } from "react";

interface IProps {
  walletAddress: string;
  setWalletAddress: (walletAddress: string) => void;
  pageLoaderStatus: number;
  setPageLoaderStatus: (pageLoaderStatus: number) => void;
}

export const Context = createContext<IProps>({} as IProps);

export const useAppContext = () => {
  return useContext(Context);
};

export default function ContextProvider({ children }: { children: ReactNode }) {
  const [walletAddress, setWalletAddress] = useState("");
  const [pageLoaderStatus, setPageLoaderStatus] = useState(0);

  return (
    <Context.Provider
      value={{
        walletAddress,
        setWalletAddress,
        pageLoaderStatus,
        setPageLoaderStatus,
      }}
    >
      {children}
    </Context.Provider>
  );
}
